<template>
    <div class="container">
        <b-img
            src="/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png"
            fluid
            alt="Responsive image"
        ></b-img>
        <h1>Funcionalidades de Smart Rooster</h1>
        <p>
            En menos de 3 minutos podrías ver un par de videos de TikTok o
            conocer todas las funciones que van a darle superpoderes a tu
            empresa. <span>¡Dale play!</span>
        </p>
        <div class="mt-1 w-100">
            <vue-plyr ref="plyr">
                <div
                    data-plyr-provider="youtube"
                    data-plyr-embed-id="-Vf9EgRvRmg"
                ></div>
            </vue-plyr>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoWizard"
};
</script>

<style lang="scss" scoped>
.container{
    text-align: center;
    p{
        span{
            font-weight: 600;
        }
    }
    //margin-bottom: 2rem;

}
</style>