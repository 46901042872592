var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-grid-wizard-welcome"},[_c('div',{staticClass:"container-reviews"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Solo 3 pasos para darle")]),_c('p',{staticClass:"subtitle"},[_vm._v("superpoderes a tu galpon")]),_c('div',{staticClass:"grid-steps"},[_c('div',{staticClass:"grid-steps-item",class:[
                        _vm.step == 1 ? 'active' : '',
                        _vm.stepSum == 1 || _vm.stepSum == 2 ? 'complete' : ''
                    ]},[_c('p',{staticClass:"number"},[_vm._v("1")]),_c('p',{staticClass:"text"},[_vm._v("Selecciona tu actividad")]),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"grid-steps-item",class:[
                        _vm.step == 2 ? 'active' : '',
                        _vm.stepSum == 2 || _vm.stepSum == 3 ? 'complete' : ''
                    ]},[_c('p',{staticClass:"number"},[_vm._v("1")]),_c('p',{staticClass:"text"},[_vm._v(" Completa la informacion de tu empresa ")]),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"grid-steps-item",class:[
                        _vm.step == 3 ? 'active' : '',
                        _vm.stepSum == 3 ? 'complete' : ''
                    ]},[_c('p',{staticClass:"number"},[_vm._v("1")]),_c('p',{staticClass:"text"},[_vm._v(" Conoce las principales funciones de Smart Rooster ")])])])])]),_c('div',{staticClass:"container-select-type"},[_c('div',{staticClass:"body d-flex flex-column align-items-center justify-content-center"},[(_vm.step == 1)?[_c('b-img',{attrs:{"src":"/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png","fluid":"","alt":"Responsive image"}}),_c('p',{staticClass:"mt-2 mb-2"},[_vm._v(" Elige el sector al que pertenece tu empresa para un inicio personalizado.👇 ")]),_c('div',{staticClass:"mt-1 w-100"},_vm._l((_vm.activityArray),function(item,index){return _c('div',{key:index,staticClass:"item-wizard-welcome d-flex align-items-center ",class:[
                            _vm.company.activity == item.label ? 'select' : ''
                        ],on:{"click":function($event){return _vm.selectActivity(item)}}},[_c('b-img',{staticClass:"image",attrs:{"src":"/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png","alt":"Responsive image"}}),_c('div',[_c('p',{staticClass:"m-0 ml-1 font-weight-bolder"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('span',{staticClass:"m-0 ml-1 font-small-3"},[_vm._v(" "+_vm._s(item.description))])])],1)}),0),_c('p',{staticClass:"config-text-step"},[_vm._v(" Puedes cambiar tus datos en cualquier momento desde la configuración ")])]:_vm._e(),(_vm.step == 2)?[_c('b-img',{attrs:{"src":"/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png","fluid":"","alt":"Responsive image"}}),_c('h1',[_vm._v("Información de tu empresa")]),_c('p',[_vm._v(" Solo unos datos más para que Alegra Contabilidad se adapte a tus necesidades ")]),_c('div',{staticClass:" w-100"},[_c('b-form-input',{staticClass:"mt-1",attrs:{"type":"text","placeholder":"Nombre de la empresa","required":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('b-form-input',{staticClass:"mt-1",attrs:{"type":"email","placeholder":"Correo de la empresa","required":""},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('div',{staticClass:"mt-1"},[_c('v-select',{attrs:{"label":"label","clearable":false,"options":_vm.regimenArray,"reduce":function (el) { return el.label; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                        var label = ref.label;
                        var description = ref.description;
                        var value = ref.value;
return [_c('h6',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(label))]),_c('p',{staticClass:"m-0 font-small-2"},[_vm._v(" "+_vm._s(description)+" ")])]}},{key:"selected-option",fn:function(ref){
                        var label = ref.label;
                        var description = ref.description;
                        var value = ref.value;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('h6',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(label))])])]}}],null,false,350021316),model:{value:(_vm.company.regime),callback:function ($$v) {_vm.$set(_vm.company, "regime", $$v)},expression:"company.regime"}})],1)],1),_c('p',{staticClass:"config-text-step"},[_vm._v(" Puedes cambiar tus datos en cualquier momento desde la configuración ")])]:_vm._e(),(_vm.step == 3)?[_c('video-wizard')]:_vm._e()],2),_c('div',{staticClass:"container-btns-steps-wizard w-100 d-flex align-items-center justify-content-between pr-5 pl-5"},[(_vm.step != 1)?_c('button',{staticClass:"btn",on:{"click":_vm.previousStep}},[_vm._v(" Anterior ")]):_c('div'),(_vm.step == 3)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.submit}},[_vm._v(" Guardar empresa ")]):_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.nextStep}},[_vm._v(" Siguiente ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }