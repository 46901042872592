// import { amgApi } from "@/service/axios";

import axios from "@/axios";

class WizardService {
    async insertCompany(body) {
        const data = await axios.post(`http://localhost:8000/api/company/create-by-wizard`, body);
        return data;
    }
}
export default new WizardService();
