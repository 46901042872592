<template>
    <div class="container-grid-wizard-welcome">
        <div class="container-reviews">
            <div class="body">
                <h2 class="title">Solo 3 pasos para darle</h2>
                <p class="subtitle">superpoderes a tu galpon</p>
                <div class="grid-steps">
                    <div
                        class="grid-steps-item"
                        :class="[
                            step == 1 ? 'active' : '',
                            stepSum == 1 || stepSum == 2 ? 'complete' : ''
                        ]"
                    >
                        <p class="number">1</p>
                        <p class="text">Selecciona tu actividad</p>
                        <div class="line"></div>
                    </div>
                    <div
                        class="grid-steps-item"
                        :class="[
                            step == 2 ? 'active' : '',
                            stepSum == 2 || stepSum == 3 ? 'complete' : ''
                        ]"
                    >
                        <p class="number">1</p>
                        <p class="text">
                            Completa la informacion de tu empresa
                        </p>
                        <div class="line"></div>
                    </div>
                    <div
                        class="grid-steps-item"
                        :class="[
                            step == 3 ? 'active' : '',
                            stepSum == 3 ? 'complete' : ''
                        ]"
                    >
                        <p class="number">1</p>
                        <p class="text">
                            Conoce las principales funciones de Smart Rooster
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-select-type">
            <div
                class="body d-flex flex-column align-items-center justify-content-center"
            >
                <template v-if="step == 1">
                    <b-img
                        src="/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png"
                        fluid
                        alt="Responsive image"
                    ></b-img>
                    <p class="mt-2 mb-2">
                        Elige el sector al que pertenece tu empresa para un
                        inicio personalizado.👇
                    </p>
                    <div class="mt-1 w-100">
                        <div
                            v-for="(item, index) in activityArray"
                            :key="index"
                            class="item-wizard-welcome d-flex align-items-center "
                            @click="selectActivity(item)"
                            :class="[
                                company.activity == item.label ? 'select' : ''
                            ]"
                        >
                            <b-img
                                class="image"
                                src="/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png"
                                alt="Responsive image"
                            ></b-img>
                            <div>
                                <p class="m-0 ml-1 font-weight-bolder">
                                    {{ item.label }}
                                </p>
                                <span class="m-0 ml-1 font-small-3">
                                    {{ item.description }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <p class="config-text-step">
                        Puedes cambiar tus datos en cualquier momento desde la
                        configuración
                    </p>
                </template>
                <template v-if="step == 2">
                    <b-img
                        src="/images/_/_/_/_/smartroosternew/frontend/src/assets/images/logo/logogallo2.png"
                        fluid
                        alt="Responsive image"
                    ></b-img>
                    <h1>Información de tu empresa</h1>
                    <p>
                        Solo unos datos más para que Alegra Contabilidad se
                        adapte a tus necesidades
                    </p>
                    <div class=" w-100">
                        <b-form-input
                            class="mt-1"
                            type="text"
                            placeholder="Nombre de la empresa"
                            required
                            v-model="company.name"
                        ></b-form-input>
                        <b-form-input
                            class="mt-1"
                            type="email"
                            placeholder="Correo de la empresa"
                            required
                            v-model="company.email"
                        ></b-form-input>
                        <div class="mt-1">
                            <v-select
                                v-model="company.regime"
                                label="label"
                                :clearable="false"
                                :options="regimenArray"
                                :reduce="el => el.label"
                            >
                                <template
                                    #option="{ label, description, value }"
                                >
                                    <h6 style="margin: 0">{{ label }}</h6>
                                    <p class="m-0 font-small-2">
                                        {{ description }}
                                    </p>
                                </template>

                                <template
                                    #selected-option="{ label, description, value}"
                                >
                                    <div
                                        style="display: flex; align-items: baseline"
                                    >
                                        <h6 style="margin: 0">{{ label }}</h6>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <p class="config-text-step">
                        Puedes cambiar tus datos en cualquier momento desde la
                        configuración
                    </p>
                </template>
                <template v-if="step == 3">
                    <video-wizard />
                </template>
            </div>
            <div
                class="container-btns-steps-wizard w-100 d-flex align-items-center justify-content-between pr-5 pl-5"
            >
                <button v-if="step != 1" @click="previousStep" class="btn">
                    Anterior
                </button>
                <div v-else></div>

                <button
                    v-if="step == 3"
                    @click="submit"
                    class="btn btn-primary"
                >
                    Guardar empresa
                </button>
                <button v-else @click="nextStep" class="btn btn-primary">
                    Siguiente
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import VideoWizard from "./VideoWizard.vue";
import WizardService from "../wizard.service";
import { mapState } from "vuex";

export default {
    name: "InitCompanyWizard",
    components: { vSelect, VideoWizard },
    data() {
        return {
            step: 1,
            stepSum: 0,
            wizardInit: {
                regime: null
            },
            regimenArray: [
                {
                    label: "Seleccione el regimen de su empresa",
                    description: "Descripcion del regimen",
                    value: "Seleccione el regimen de su empresa"
                },
                {
                    label: "Regimen especial",
                    description:
                        "Negocios con un ingreso anual menor a S/. 525.000",
                    value: 1
                },
                {
                    label: "Regimen general",
                    description: "Negocios sin liite de ingresos",
                    value: 2
                },
                {
                    label: "Regimen MYPE tributario",
                    description:
                        "Negocios con un ingreso anual menor o igual a 1700 UIT",
                    value: 3
                },
                {
                    label: "Regimen RUS",
                    description:
                        "Nogocios no obligados a llevar contabilidad con un ingreso anual hasta S/. 96.000",
                    value: 4
                }
            ],
            activityArray: [
                {
                    label: "Actividad 1",
                    description: "Descripcion 1 Descripcion 1 Descripcion 1"
                },
                {
                    label: "Actividad 2",
                    description: "Descripcion 2 Descripcion 2"
                },
                {
                    label: "Actividad 3",
                    description: "Descripcion 3 Descripcion 2"
                }
            ],
            company: {
                activity: null,
                name: null,
                regime: "Seleccione el regimen de su empresa",
                email: null,
                idUser: null
            }
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    created() {},
    mounted() {
        this.company.idUser = this.currentUser.user_id;
    },
    methods: {
        nextStep() {
            if (this.step < 3) {
                this.step = this.step + 1;
                this.stepSum = this.stepSum + 1;
            }
        },
        previousStep() {
            if (this.step > 1) {
                this.step = this.step - 1;
                this.stepSum = this.stepSum - 1;
            }
        },
        async submit() {
            this.isPreloading(true);
            //console.log("submit");
            const resp = await WizardService.insertCompany(this.company);
            //console.log("resp: ", resp.data);
            setTimeout(() => {
                this.isPreloading(false);
                if (resp.data.length > 0) {
                    this.$router.push("/company/select");
                } else {
                    this.$router.push("/company");
                }
            }, 2000);
        },
        selectActivity(activity) {
            this.company.activity = activity.label;
        }
    },
    watch: {}
};
</script>

<style lang="scss" scoped>
.container-grid-wizard-welcome {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 2fr 5fr;
    .container-reviews {
        background: #d5d1fb;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 0;
        .title {
            font-size: 20px;
            text-align: center;
        }
        .subtitle {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: #7367f0;
        }
        .grid-steps {
            margin-top: 2rem;

            &-item {
                display: grid;
                grid-template-columns: 1fr 8fr;
                margin-bottom: 4rem;
                position: relative;
                :last-child {
                    margin-bottom: 0;
                }
                .line {
                    position: absolute;
                    top: 0;
                    left: 10.5px;
                    height: 100px;
                    width: 3px;
                    background: #fff;
                    z-index: -1;
                }
                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 24px;
                    width: 24px;
                    border-radius: 100%;
                    background: #fff;
                    color: #a2a2a2;
                    border: 2px solid #a2a2a2;
                    margin: 0;
                    transition: 0.3s all ease-in-out;
                    font-weight: 600;
                }
                .text {
                    margin: 0;
                    font-size: 16px;
                    margin-left: 0.5rem;
                }
            }
            .active {
                .number {
                    color: #7367f0;
                    border-color: #7367f0;
                }
            }
            .complete {
                .number {
                    background: #7367f0;
                    color: #fff;
                    border-color: #7367f0;
                }
                .line {
                    background: #7367f0;
                }
            }
        }
    }
    .container-select-type {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 100%;
        min-height: 100%;
        .body {
            max-width: 640px;
            flex: 1;
            .item-wizard-welcome {
                cursor: pointer;
                box-shadow: 1px 2px 5px rgb(0 0 0 / 10%);
                padding: 1rem;
                border-radius: 6px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 0.8rem;
                transition: 0.3s all ease-in-out;
                .image {
                    height: 20px;
                    width: 20px;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .select {
                background: #d5d1fb;
            }
        }
        .container-btns-steps-wizard {
        }
    }
}
.config-text-step {
    margin: 0;
    text-align: center;
    margin-top: 1.5rem;
    color: #707070;
    font-size: 14px;
}
.plyr__video-embed {
    background: red;
}
</style>
