import { render, staticRenderFns } from "./VideoWizard.vue?vue&type=template&id=09452708&scoped=true"
import script from "./VideoWizard.vue?vue&type=script&lang=js"
export * from "./VideoWizard.vue?vue&type=script&lang=js"
import style0 from "./VideoWizard.vue?vue&type=style&index=0&id=09452708&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09452708",
  null
  
)

export default component.exports